import React from "react"

import LayoutBackgroundBurrito from "../components/layout-background-burrito"


const MenuPage = () => {
    return (
        <LayoutBackgroundBurrito>
            <h1> Menu </h1>
            <h2> Here will be the hungry turtle menu </h2>
        </LayoutBackgroundBurrito>
    )
}

export default MenuPage